import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux';
import Results from './Results';
import { getCategoryVideos } from '../../actions/filterActions';
import { useNavigate } from 'react-router-dom';

const CategoryResults = (props) => {

    const {category_title, category_id} = useParams()

    const navigate = useNavigate()

    const {error, loading, categoryVideos } = useSelector(state => state.categoryVideos)

    if(props.userLoading){
      return (<div>Loading...</div>)
    } else {
      if(props.user){
    return (
        <>
        {
            // categoryVideos && 
            <Results
                data = {categoryVideos}
                title = {category_title}
                loading = {loading}
                error = {error}
                setVideo = {props.setVideo}
                filterActive = {props.filterActive}
                setFilterActive = {props.setFilterActive}
                searchFunc = {getCategoryVideos}
                val = {category_id}
            />
        }
        </>
    );
    } else {
        navigate('/login')
    }

    }
}

export default CategoryResults;