import React, {useState, useEffect} from 'react';
import Slider from '../slides/Slider'
import { getLatestPlaylists } from '../../actions/PlaylistActions'
import { useDispatch, useSelector } from 'react-redux';
import ArrowRight from '../icons/Arrow_right.svg'
import { useNavigate } from 'react-router-dom';


function NewVideos(props) {

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const { latestPlaylists} = useSelector(state => state.latestPlaylists)
  
  
    useEffect(()=>{ 
  
      dispatch(getLatestPlaylists())
  
    },[dispatch])


    const clickHandler = (item) => {
        localStorage.setItem('playlist_id', item.id)
        localStorage.setItem('video_id', item.video.id)
        localStorage.setItem('group_id', item.group_id)
        if(props.user) {
          navigate(encodeURI(`/home?g=${item.group_id}&p=${item.id}&v=${item.video.id}`))
        } else {
          navigate('/login')
        }
    }


    return (
        <div className="col-12" ref = {props.newPlaylistsRef}>
            <div className="col-12 ps-5 d-flex pb-2 align-items-center" style = {{marginBottom:"0.5vw", fontSize:"1.25rem", color:'white'}}>
                <span className="text-white fw-bold fs-7 ps-4 pe-3">New  Playlists</span>
                <img src = {ArrowRight} alt = '' style={{cursor:"pointer"}}/>
            </div>
            <div className='position-relative'>
                    {
                        latestPlaylists && latestPlaylists && latestPlaylists.length ?
                        <Slider
                            num = {7}
                            type = {5}
                            showTitle = {false}
                            tvData={latestPlaylists}
                            slideClickHandler={clickHandler}
                        /> : ""
                    }
            </div>
        </div>
    );
}

export default NewVideos;