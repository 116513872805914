import React, { useState} from 'react';
import Slider from 'react-slick'

const BottomTracks = (props) => {

    const [hoverTrack, setHoverTrack] = useState()


    const get_members = (data) =>{
        let members = ''
    
        if (data.artists && data.artists.length){
          members = data.artists
      }
    
      if (data.composers && data.composers.length){
          let c = data.composers.split(',')
          for (let i in c){
              if (!members.includes(c[i])){
                  members += ', '+ c[i]
              }
          }    
      }
        return members
    }


    const settings = {
        className: "slider variable-width",
        arrows: false,
        centerMode: false,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 3,
        variableWidth: true,
      };

    return (
        <>
        {
            <div 
            className={!props.playing || props.menuVisible ? 'w-100 visible position-relative' : 'w-100 position-relative hidden'}
                style={{backgroundColor:"#07294C80", zIndex:'150'}}
                >

                {
                    props.video && props.video.tracks && props.video.tracks.length && props.activeTrack ?
                    <Slider {...settings} ref={props.setTracksRef} slickGoTo>
                    { 
                        props.video.tracks.map((track, index) => (
                            <div
                                key = {index}
                                role = 'button'
                                className='px-2 d-flex align-items-center'
                                onClick={(e)=>props.trackClickHandler(e,track)}
                                onMouseEnter={()=>{setHoverTrack(track.id); }}
                                onMouseLeave={()=>{setHoverTrack()}}
                            >
                                <div
                                    className= {
                                        props.activeTrack.position === track.position ? 
                                        ' h-100 text-nowrap p-2 pe-3 d-flex flex-column rounded bg-info':
                                        ' h-100 text-nowrap overflow-hidden p-2 pe-3 d-flex flex-column'
                                    } 
                                    style={
                                        {
                                            background:props.activeTrack.position === track.position && '#79F7FF',
                                            height:'38px',
                                            width: 'auto',
                                            transition: "all 0.5s ease-in-out",
                                            maxWidth: hoverTrack === track.id ? `${track.title.length > get_members(track).length ? track.title.length*9 : get_members(track).length*9}px` : "293px"
                                        } }
                                    >
                                    <h6
                                        className={
                                            props.activeTrack.position === track.position? 
                                            'text-primary fw-bold overflow-hidden m-0' : 
                                            'text-white fw-bold overflow-hidden m-0'}
                                        // style={{height:"auto"}}
                                        title={track.title}>{track.title}</h6>
                                        {
                                            get_members(track).length ? 
                                            <h6
                                            className={
                                                props.activeTrack.position === track.position ? 
                                                'text-primary overflow-hidden m-0' : 
                                                'text-info overflow-hidden m-0'}
                                                style={{maxHeight:'40px'}}
                                                title={get_members(track)}>{get_members(track)}</h6> : ""
                                        }
                                </div>
                                <div style={props.activeTrack.position === track.position ? {}: {width:'1px', height:'30px', background:'white'}}></div>
                            </div>
                        ))
                    } 
                </Slider> :''
                }

            </div> 
        }
        </>
    );
};

export default BottomTracks;