import React, {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listPlaylistVideos } from '../../actions/PlaylistActions'
import { useParams } from 'react-router-dom'
import arrowLeft from '../icons/Arrow_left.svg'
import { useNavigate } from 'react-router-dom';
import default_cover from '../images/default_cover.jpg'
const PlaylistPage = () => {

    const {playlist_id, playlist_title} = useParams()

    const dispatch = useDispatch()

    const navigate = useNavigate()
  
    const { loading, playlistVideos } = useSelector(state => state.userPlaylists)
  
    useEffect(()=>{
  
      dispatch(listPlaylistVideos(playlist_id))
  
    },[dispatch, playlist_id])



    const get_members = (data) =>{
        let members = ''
    
        if (data.artists && data.artists.length){
          members = data.artists
      }
    
      if (data.composers && data.composers.length){
          let c = data.composers.split(',')
          for (let i in c){
              if (!members.includes(c[i])){
                  members += ', '+ c[i]
              }
          }    
      }
        return members
    }


    const clickHandler = (video) => {
        let group_id = localStorage.getItem('group_id')
        localStorage.setItem('video_id', video.id)
        localStorage.setItem('playlist_id', playlist_id)
        navigate(encodeURI(`/home?g=${group_id}&p=${playlist_id}&v=${video.id}`))
    }

    function toTimeString(seconds) {
        let inTime = (new Date(seconds * 1000)).toUTCString().match(/(\d\d:\d\d:\d\d)/)[0];
        if(seconds < 3600){
            inTime = inTime.slice(3, 8)
        }
        return (inTime)
      }

    return (
        <div className = "w-100 min-vh-100 bg-bluedark d-flex flex-column align-items-center">
        <div className = "w-100 d-flex flex-column align-items-center pb-5">
        { 
            loading ? <div className = "text-info col-12 col-lg-10" >Loading...</div> :
            <>
                <div className = 'w-100 d-flex justify-content-center pt-5 pb-5'>
                    <div className = 'col-12 px-5 col-lg-10 d-flex'>
                        <img 
                            src = {arrowLeft} 
                            role = 'button'
                            onClick={()=>navigate(-1)}
                            alt = ''/>
                        <div className='ps-3 text-white fs-7 fw-bold'>{playlist_title}</div>
                    </div>
                </div>

                <div className='col-12 col-lg-10 px-4 d-flex flex-row flex-wrap'>
                    {
                        playlistVideos && playlistVideos.length ? 
                        playlistVideos.map((video)=>(
                                <div key = {video.id}
                                    className = "col-6 col-md-4 col-lg-3 px-1 py-2 d-flex align-items-start"
                                    onClick={()=>clickHandler(video)}
                                    >
                                    <div className='w-100' >
                                        <div className='position-relative'
                                        >
                                            <img src={ `https://img.cugate.com/?o=CUTV_VIDEO&i=${video.id}&s=300`} alt = ""
                                                onError = {({ currentTarget }) => {
                                                    currentTarget.onerror = null // prevents looping
                                                    currentTarget.src=default_cover
                                                }}
                                                role='button'
                                                className='w-100 px-1'
                                                style={
                                                    {
                                                        objectFit: 'cover',
                                                        borderRadius:'0.625vw',
                                                        aspectRatio: "16/9"
                                                    }
                                                }>
                                                </img>
                                                <div className='position-absolute text-white rounded px-1 bg-dark'
                                                    style={{zIndex:"2", bottom:"0.05rem", right:"0.2rem"}}>
                                                    {toTimeString(video.duration)}
                                                </div>
                                        </div>
                                        <div className = "w-100 d-flex flex-row align-items-start pt-1 pt-lg-3">
                                            <div
                                                className='ms-0 w-100 ms-lg-3 text-wrap px-1 d-flex flex-column'
                                                >
                                                <h6
                                                    className='text-white fw-bold overflow-hidden m-0'
                                                    // style={{height:"auto"}}
                                                    title={video.title}>{video.title}</h6>
                                                    {
                                                        get_members(video).length ? 
                                                        <h6
                                                            className='text-info overflow-hidden'
                                                            style={{maxHeight:'40px'}}
                                                            title={get_members(video)}>{get_members(video)}</h6> : ""
                                                    }
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                        )) : ""
                    }
                </div>
            </>
        }
        </div>  
        
        </div>
    );
};

export default PlaylistPage;