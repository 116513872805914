import React, {useRef, useState} from 'react';
import ReactPlayer from 'react-player/lazy'

const SlideWithVideo = (props) => {

    const activePlaylistRef = useRef()

    const [hover, setHover] = useState()


    return (
        <div className='px-2 h-100' key={props.index} 
            onClick = {()=>{props.slideClickHandler(props.item)}}
            onMouseMove={()=>setHover(props.item.id)}
            onMouseLeave={()=>setHover()}>
            <div className = 'w-100' style = {{cursor:"pointer", aspectRatio:'16/9'}} >
                { 
                    hover && hover === props.item.id &&  props.item.video.scripted_bunny_path?
                    <ReactPlayer
                        ref = {activePlaylistRef }
                        className='react-player'
                        // controls
                        url = {props.item.video.scripted_bunny_path}
                        // onReady={()=>readyHandler()}
                        playing = {true}
                        muted = {true}
                        // light = {`https://img.cugate.com/?o=CUTV_PLAYLIST&i=${props.item.id}&s=600`}
                        config={{
                        file: { 
                            attributes: {
                            controlsList: 'nodownload noprops.fullscreen'
                            }
                        }
                        }}
                        width="100%"
                        height='100%'
                    />   :
                    <div className='h-100 w-100'>
                        <img src = {`https://img.cugate.com/?o=CUTV_PLAYLIST&i=${props.item.id}&s=600`}
                            alt = "aaaaaa" 
                            className='rounded'
                            style = { {
                                objectFit: 'cover',
                                width:"100%", 
                                 }}
                            onError = {({ currentTarget }) => {
                                currentTarget.onerror = null // prevents looping
                                // currentTarget.src=Default
                                }}    
                        />  
                    </div>
                }
            </div>
    </div>
    );
};

export default SlideWithVideo;