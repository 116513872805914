import React from 'react';
import Apple from '../icons/Apple.svg'
import Amazon from '../icons/Amazon.svg'
import Cuview from '../icons/CUVIEW.svg'
import Spotify from '../icons/SPOTIFY.svg'
import Deezer from '../icons/DEEZER.svg'
import Pandora from '../icons/PANDORA.svg'
import teIcon from '../icons/Te.png'
import QRCode from "react-qr-code";
import merchIcon from '../icons/merchbar_logo_white.svg'
import { useNavigate } from 'react-router-dom';



const AnalyzeBody = ( props ) => {

    const navigate = useNavigate()

    const iconClickHandler = (url) =>{

        window.open(url)
    }

    const memberClickHandler = (member) => {
        navigate(`/filter_results/${member.id}!7`)
    }

    return (
        <>
            { props.activeData && props.activeData.album ?
                <div className = "w-100 mx-auto px-3 d-flex flex-column flex-md-row flex-lg-row">
                    <div className='col-12 col-lg-9 col-md-9 d-flex flex-column flex-md-row flex-lg-row'>
                        <div className = "col-8 mx-auto mx-md-0 col-lg-3 col-md-4 text-center">
                            <img 
                                src = {props.activeData.album.cover_url} alt=""
                                role="button"
                                onClick = {() => iconClickHandler(props.activeData.album.url)}
                                className = "col-12 shadow-lg" style = {{width:"95%"}}/>
                        </div>
                        <div className='col-12 col-lg-8 col-md-8 ps-md-4 ps-lg-5 pt-2'>
                            <div className = "col-12 text-center text-md-start text-lg-start">
                                <div className='d-flex'>
                                    <div className="fs-2 fw-bold text-white pe-3"
                                        role="button"
                                        onClick = {() => iconClickHandler(props.activeData.track_url)}
                                    >
                                        {props.activeData.track_title}
                                    </div>
                                    <img 
                                        style={{width:'30px', height:'30px'}}
                                        src = {teIcon} 
                                        alt = ""
                                        role = "button"
                                        onClick={()=>window.open(`https://te.cugate.com/?act=track_culink&ref=album&ids=${props.activeData.track_id};&tracksnum=1&album_id=${props.activeData.album.id}&link_type=Extra+Link`)}
                                    />
                                </div>

                                {
                                    props.analyzeGenre && 
                                    <div className="fs-3 fw-bold text-white"
                                        role="button"
                                    >
                                        Genre: {props.analyzeGenre}
                                    </div>
                                }
                                {
                                    props.analyzeMood && 
                                    <div className="fs-3 fw-bold text-white"
                                        role="button"
                                    >
                                        Mood: {props.analyzeMood}
                                    </div>
                                }
                                    {
                                        props.activeData && props.activeData.members ? 
                                        props.activeData.members.map((member, index)=>
                                        <div className='d-flex align-items-center' key={index}>
                                            <div 
                                                className = "text-white pt-0 pt-lg-2" 
                                                role="button"
                                                onClick = {() => memberClickHandler(member)}>
                                                {member.title2}
                                            </div>
                                            <img 
                                                style={{width:"2rem", paddingLeft:"0.5rem" }}
                                                src = {merchIcon} 
                                                role = "button"
                                                onClick={()=>iconClickHandler(member.merch_url)}
                                                alt = ""/>
                                            <img 
                                                style={{width:'30px', height:'30px', marginLeft:"0.5rem" }}
                                                src = {teIcon} 
                                                alt = ""
                                                role = "button"
                                                onClick={()=>window.open(`https://te.cugate.com/index.php?act=member_view&ref=members&ids=${member.id};&membersnum=1`)}
                                            />
                                        </div>) : ""
                                    }
                            </div>
                        </div>  
                    </div>
                    <div className='col-12 col-lg-3 col-md-3 pt-3 pt-md-0'>
                        {
                            props.activeData.culinks ?
                            <div className = "col-8 col-md-10 col-lg-8 mx-auto mx-md-0 mx-lg-0">
                                <img 
                                    className = "col-2 pe-2 pe-md-1 pe-lg-2" 
                                    src = {Apple}
                                    role = "button"
                                    alt = ''
                                    onClick = {() => iconClickHandler(props.activeData.culinks.ITUNES.url)}
                                    />
                                <img 
                                    className = "col-2 pe-2 pe-md-1 pe-lg-2" 
                                    src = {Amazon}
                                    role = "button"
                                    alt = ''
                                    onClick = {() => iconClickHandler(props.activeData.culinks.AMAZON.url)}/>
                                <img 
                                    className = "col-2 pe-2 pe-md-1 pe-lg-2" 
                                    src = {Cuview}
                                    role = "button"
                                    alt = ''
                                    onClick = {() => iconClickHandler(props.activeData.culinks.CUVIEW.url)}/>
                                <img 
                                    className = "col-2 pe-2 pe-md-1 pe-lg-2" 
                                    src = {Spotify}
                                    role = "button"
                                    alt = ''
                                    onClick = {() => iconClickHandler(props.activeData.culinks.SPOTIFY.url)}/>
                                <img 
                                    className = "col-2 pe-2 pe-md-1 pe-lg-2" 
                                    src = {Deezer}
                                    role = "button"
                                    alt = ''
                                    onClick = {() => iconClickHandler(props.activeData.culinks.DEEZER.url)}/>
                                <img 
                                    className = "col-2 pe-2 pe-md-1 pe-lg-2" 
                                    src = {Pandora}
                                    role = "button"
                                    alt = ''
                                    onClick = {() => iconClickHandler(props.activeData.culinks.RDIO.url)}/>
                            </div> : ""
                        }
                        <div  
                        className='mt-3 mt-lg-5 mt-md-5 col-4 col-md-8 col-lg-4 rounded mx-auto mx-md-0 mx-lg-0'
                        style={{ height: "auto", background:"white", padding:"0.7vw" }}>
                            <QRCode
                                size={256}
                                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                value={props.activeData && props.activeData.wm1_info && props.activeData.wm1_info.code && props.activeData.wm1_info.code === '2147392886' ? 'https://shop.rewe.de/p/nutella-750g/5095220' : props.resultLink}
                                viewBox={`0 0 256 256`}
                            />
                        </div>
                    </div>
                </div> : ""
            }
        </>
    );
}

export default AnalyzeBody; 