import React, {useEffect, useState} from 'react';
import AdminVideoGenres from './AdminVideoGenres';
import AdminTags from './AdminTags';
import AdminGenres from './AdminGenres';
import AdminMoods from './AdminMoods';
import { useDispatch, useSelector } from 'react-redux';
import SwitchOn from '../adminIcons/SwitchOn.svg'
import SwitchOff from '../adminIcons/SwitchOff.svg'
import UploadForEdit from './UploadForEdit';
import { editVideo } from '../../actions/videoActions';
import { useParams, useNavigate } from 'react-router-dom';
import { getVideoInfo } from '../../actions/videoActions';
import BackIcon from '../adminIcons/BackIcon.svg'
import teIcon from '../adminIcons/Te.png'


const EditVideo = (props) => {

    const {video_id} = useParams()

    useEffect(()=>{
        dispatch(getVideoInfo(video_id))
    },[])

    const {videoInfo} = useSelector(state=>state.videoInfo)

    const [title, setTitle] = useState('')

    const [description, setDescription] = useState('')

    const [youtubeLink, setYoutubeLink] = useState('')

    const [genres, setGenres] = useState([])

    const [moods, setMoods] = useState([])

    const [videoGenres, setVideoGenres] = useState([])

    const [tags, setTags] = useState([])

    const [addedTracks, setAddedTracks] = useState([])

    const [statusOn, setStatusOn] = useState(0)

    const [videoUrl, setVideoUrl] = useState()

    const [imageFile, setImageFile] = useState()

    const [imageURL, setImageUrl] = useState()

    const navigate = useNavigate()

    const dispatch = useDispatch()


    useEffect(()=>{
        if (videoInfo && videoInfo.id){
            setTitle(videoInfo.title)
            setDescription(videoInfo.description)
            setYoutubeLink(videoInfo.yt_link)
            setGenres(videoInfo.genres)
            setMoods(videoInfo.moods)
            setVideoGenres(videoInfo.videogenres)
            setTags(videoInfo.tags)
            setAddedTracks(videoInfo.tracks)
            setStatusOn(videoInfo.status)
            setImageUrl(`https://img.cugate.com/?o=CUTV_VIDEO&i=${videoInfo.id}&s=600`)
            setVideoUrl(videoInfo.scripted_bunny_path ?  videoInfo.scripted_bunny_path : 'https://prelistening.cugate.com/cloud_audiofiles/' + videoInfo.file_path.slice(3,))
        }
    },[videoInfo])


    function toTimeString(seconds) {
        const hrs = Math.floor(seconds / 3600);
        const mins = Math.floor((seconds % 3600) / 60);
        const secs = Math.floor(seconds % 60);
    
        return [
            hrs > 0 ? String(hrs).padStart(2, '0') : null,
            String(mins).padStart(2, '0'),
            String(secs).padStart(2, '0')
        ].filter(Boolean).join(':');
    }



    const get_members = (data) =>{

        let members = '' 
        
        if (data.artists && data.artists.length){
            members = data.artists
        }

        if (data.composers && data.composers.length){
            let c = data.composers.split(',')
            for (let i in c){
                if (!members.includes(c[i])){
                    members += ', '+ c[i]
                }
            }    
        }
        return members
    } 

    const saveHandler = () => {
        if (!title.length){
            alert('Please, Provide Title')
        } else if (!genres.length){
            alert('Please, Provide Genre')
        } else if(!moods.length){
            alert('Please, Provide Mood')
        } else if(!videoGenres.length){
            alert('Please, Provide Video genre')
        } else if(!tags.length){
            alert('Please, Provide Tags')
        } else if(!addedTracks.length){
            alert('Please, Add Tracks')
        } else {
            let videoInfo = {}
            videoInfo.title = title
            videoInfo.description = description
            videoInfo.yt_link = youtubeLink
            videoInfo.genres = genres.map(genre=>parseInt(genre.id))
            videoInfo.moods = moods.map(mood=>parseInt(mood.id))
            videoInfo.videogenres = videoGenres.map(videoGenre=>parseInt(videoGenre.id))
            videoInfo.tags = tags.map(tag=>parseInt(tag.id))
            videoInfo.status = statusOn
            dispatch(editVideo(video_id, videoInfo))
            props.setChanges(false)
            navigate(-1)
        }
    }

    const backHandler = () => {
        if(props.changes){
            var answer = window.confirm("You may loose the changes you made");
            if(answer){
                props.setChanges(false)
                navigate(-1)
            }
        } else {
            navigate(-1)
        }
    }


    return (

        <div className = "col-10 p-4" style={{marginLeft:"16vw", minHeight:"100vh"}}>
            {
                videoInfo ?
                <>
                    <div className='d-flex align-items-center pb-4'>
                        <img 
                            src = {BackIcon} 
                            role = 'button'
                            onClick={backHandler}
                            alt = ""/>
                        <div className = "fw-bold fs-7 ps-4 text-secondary" 
                            role='button' 
                        >{videoInfo.title}</div>
                    </div>
                    <div className = "w-100 d-flex pb-5">
                        <div className = "col-7 bg-success me-3 rounded p-3 pb-5">
                            <div className = "text-secondary fw-bold pb-2">Title*</div>
                            <input
                                type="text"
                                className="col-12 rounded fs-5 border-0 bg-warning text-white py-2 search-input ps-3"
                                value = {title}
                                onChange={(e)=>{setTitle(e.target.value); props.setChanges(true)}}
                            />
                            <div className = "text-secondary fw-bold pb-2 pt-4">Description</div>
                            <textarea
                                type="text"
                                className="col-12 rounded fs-5 border-0 bg-warning text-white py-2 search-input addToplaylist-list-wrapper ps-3"
                                style={{height:"10rem", resize:"none"}}
                                value = {description}
                                onChange={(e)=>{setDescription(e.target.value); props.setChanges(true)}}
                            />
                            <div className = "text-secondary fw-bold pb-2">Youtube Link</div>
                                <input
                                    type="text"
                                    className="col-12 rounded fs-5 border-0 bg-warning text-white py-2 search-input ps-3"
                                    value = {youtubeLink}
                                    onChange={(e)=>{setYoutubeLink(e.target.value); props.setChanges(true)}}
                                />
                            <AdminGenres
                                genres = {genres}
                                setGenres = {setGenres}
                                setChanges = {props.setChanges}
                            />
                            <AdminMoods
                                moods = {moods}
                                setMoods = {setMoods}
                                setChanges = {props.setChanges}
                            />
                            <AdminVideoGenres
                                videoGenres = {videoGenres}
                                setVideoGenres = {setVideoGenres}
                                setChanges = {props.setChanges}
                            />
                            <AdminTags
                                tags = {tags}
                                setTags = {setTags}
                                setChanges = {props.setChanges}
                            />
                            <div className='w-100 d-flex justify-content-between pb-2 pt-4'>
                                <div className = "text-secondary fw-bold">Tracks</div>
                            </div>
                            <div className = "bg-warning w-100 rounded addToplaylist-list-wrapper" style = {{maxHeight:"500px"}}>
                                {
                                    addedTracks && addedTracks.length ? 
                                    <div>
                                        <div className = " w-100 d-flex bg-warning border-bottom border-success p-2">
                                            <div className = "text-secondary fw-bold text-wrap px-2"
                                                style={{width:"35%"}}
                                            >Track</div>
                                            <div className = "text-secondary fw-bold text-wrap px-2"
                                                style={{width:"30%"}}
                                            >Album Title</div>
                                            <div className = "text-secondary fw-bold text-wrap text-center px-2"
                                                style={{width:"18%"}}
                                            >Genre</div>
                                            <div className = "text-secondary fw-bold text-wrap px-2"
                                                style={{width:"17%"}}
                                            >Duration</div>
                                        </div>
                                        {
                                        addedTracks.map((track, index)=>(
                                            <div key = {index} className=' w-100 d-flex bg-warning border-bottom border-success align-items-center p-2'>
                                                <div style={{width:"35%"}}>
                                                    <div className = "px-2 d-flex align-items-start"
                                                        title = "Edit on TE"
                                                        role = "button"
                                                        onClick={()=>window.open(`https://te.cugate.com/?act=track_view&ref=tracks&ids=${track.track_id};&tracksnum=1`)}
                                                    >
                                                        <div className='text-white text pe-2' 
                                                        >{track.track_title}</div>
                                                        <img src = {teIcon} alt = ""/>
                                                    </div>

                                                    <div className='text-secondary px-2'
                                                        title = {get_members(track)}
                                                    >{get_members(track)}</div>
                                                </div>
                                                <div style={{width:"30%"}}>
                                                    <div className='text-white text-wrap whitespace-nowrap px-2'
                                                    >{track.album_title}</div>
                                                    <div className='text-secondary px-2 fw-bold'
                                                    >{track.catalogue_num}</div>
                                                </div>
                                                <div className='text-white text-wrap px-2 text-center'
                                                    style={{width:"18%"}}
                                                >{track.genre}</div>
                                                <div className='text-white text-wrap px-2'
                                                    style={{width:"17%"}}
                                                >{toTimeString(track.start_time) + '-' + toTimeString(track.end_time)}</div>
                                            </div>
                                    ))                             
                                    }</div> : ""
                                }
                            </div>
                            <div className = "py-5" role = "button">
                            {
                                statusOn === 0 ? 
                                <div className = "d-flex align-items-center">
                                    <img src = {SwitchOff} alt = "" onClick={()=>{setStatusOn(1); props.setChanges(true)}}/>
                                    <div className = "text-info ms-4">Access OFF</div>
                                </div>
                                    : 
                                    <div className = "d-flex align-items-center">
                                    <img src = {SwitchOn} alt = "" onClick={()=>{setStatusOn(0); props.setChanges(true)}}/>
                                    <div className = "text-info ms-4">Access ON</div>
                                </div>
                            }
                            </div>
                            <div className = "w-100 d-flex justify-content-end">
                            <div className = "text-white px-5 py-2 bg-secondary rounded-pill"
                                    role = 'button'
                                    onClick={()=>saveHandler()}
                                >Save</div> 
                            </div>
                        </div>
                        <UploadForEdit
                            videoUrl = {videoUrl}
                            imageFile = {imageFile}
                            setImageFile = {setImageFile}
                            imageURL = {imageURL}
                            setImageUrl = {setImageUrl}
                            video_id = { video_id }
                        />
                    </div>
                </> : <div className='text-info'>Please try again</div>
            }
        </div>
    );
};

export default EditVideo;