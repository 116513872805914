import React, {useEffect, useState} from 'react';
import { getActiveGenres } from '../../actions/filterActions';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import Slider from '../slides/Slider';


function Genres(props) {

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const { error, activeGenres} = useSelector(state => state.activeGenres)

    useEffect(()=>{

        dispatch(getActiveGenres())

    },[dispatch])

    const clickHandler = (item) => {

        if (props.user) {
            navigate(`/filter_results/${item.id}!1`)
        } else {
            navigate('/login')
        }

    }


    return (
        <div>
        <div className="col-12" style = {{marginTop:"2.5vw"}}>
            <div className="col-12 ps-5 pb-2 d-flex align-items-center" style = {{ fontSize:"1.25rem", color:'white'}}>
                <span className="text-white fw-bold fs-7 ps-4 pe-3">Genres</span>
            </div>
            <div className='position-relative'>
                <Slider
                        num = {7}
                        type = {3}
                        showTitle = {false}
                        tvData={activeGenres}
                        slideClickHandler={clickHandler}
                    /> : ""
            </div>
        </div>
        </div>
    );
}

export default Genres;