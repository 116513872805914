import React, {useEffect, useState} from 'react';
import checkSeveral from '../icons/CheckSeveral.svg'
import Down from '../icons/Down.svg'
import ResultComponent from './ResultComponent';
import Modal from '../Modal';
import AddVideoToPlaylistContent from '../playlistAdds/AddVideoToPlaylistContent';
import useWindowDimentions from '../useWindowDimentions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';


const Results = (props) => {

    const [checkActive, setCheckActive] = useState(false)

    const [videoList, setVideoList] = useState([])

    const [selected, setSelected] = useState(false)

    const [addActive, setAddActive] = useState(false)

    const [right, setRight] = useState(0)

    const {width, height} = useWindowDimentions()

    const [top, setTop] = useState(0)

    const [page, setPage] = useState(1)

    const [selectedVideo, setSelectedVideo] = useState()

    const {active_pl_id, loadingTemp, group_id} = useSelector(state=> state.userPlaylists)

    const navigate = useNavigate()

    const dispatch = useDispatch()

    const selectAllHandler = () => {
        setCheckActive(true)
        if(selected){
            setVideoList([])
        } else {
            setVideoList(props.data.map(i=>i.id))
        }
        setSelected(!selected)
    }

    const addClickHandler = (e) => {
        setRight(width-e.clientX+5)
        setTop(e.clientY)
        setAddActive(true)
    }

    useEffect(()=>{
        // props.setVideo(selectedVideo)
        // let l = filterList.split('|').map(fl=>fl.split('!').map(i=>parseInt(i)))
        // let a = l.filter(i => i[1]===3).map(a=>a[0]).join('!')
        // let c = l.filter(i => i[1]===4).map(c=>c[0]).join('!')
        // let query = ``
        // if(a && a.length){
        //     query = query+`&a=${a}`
        // }
        // if(c && c.length) {
        //     query = query+`&c=${c}`
        // }
        if(selectedVideo && !loadingTemp && active_pl_id && group_id){
            localStorage.setItem('group_id', group_id)
            localStorage.setItem("video_id", selectedVideo.id)
            localStorage.setItem('playlist_id', active_pl_id)
            navigate(encodeURI(`/home?g=${group_id}&p=${active_pl_id}&v=${selectedVideo.id}`))
        }
    },[loadingTemp, selectedVideo])

    useEffect(()=>{
        setPage(1)
        dispatch(props.searchFunc(props.val, page, 0))
    },[props.val])

    useEffect(()=>{
        if(page>1){
            dispatch(props.searchFunc(props.val, page, 1))
        }
    },[page])

    const handleScroll = event => {
        const {scrollTop, clientHeight, scrollHeight} = event.currentTarget;
        
        let h = Math.round(scrollHeight - scrollTop)
        if(h === clientHeight || h+1 === clientHeight || h-1 === clientHeight){
          setPage(prev => prev + 1)
        }
      }


      useEffect(()=>{
        setCheckActive(false)
        setVideoList([])
      },[props.data])


    return (
        <>
            <div className = "container-fluid min-vh-100 d-flex flex-column align-items-center"
            style={{background:"linear-gradient(#051932, #07294C)", padding:0, margin:0, minHeight:"100vh"}}>
                <div className = "w-100 d-flex flex-column align-items-center pb-5">
                    <div className='w-100 bg-dark top-0 d-flex justify-content-center'>
                        <div className='col-12 col-lg-10 px-1 px-lg-4 py-2 d-flex justify-content-between '>
                            <div className = "text-info pt-2 fs-4">{props.title}</div>
                            <div className='d-flex align-items-center' style={{gap:"1rem"}}>
                                {
                                    checkActive ? 
                                    <>
                                        <div
                                            role = "button"
                                            className='rounded-pill px-3 py-1 d-flex'
                                            style={videoList && videoList.length ? {background:"#FFCD00", gap:"0.5rem"} :{background:"#708090", gap:"0.5rem"}}
                                            onClick={videoList && videoList.length ? (e)=>addClickHandler(e): ()=>{alert('please, choose a video')}}
                                            >
                                            <div>Add to</div>
                                            <img src={Down}/>
                                        </div>
                                        <div 
                                            style={{color:"#FFCD00"}}
                                            role='button'
                                            onClick={()=>{setCheckActive(false); setVideoList([])}}>Exit</div>
                                        </>:
                                    <button 
                                        className='rounded-pill px-4 py-1'
                                        style={{background:"#FFCD00"}}
                                        onClick={()=>setCheckActive(!checkActive)}
                                        >Select</button>
                                }
                                <div className='d-flex'
                                    role='button'
                                    onClick = {()=>selectAllHandler()}>
                                    <div 
                                        style={{color:"#FFCD00"}}
                                        >All</div>
                                    <img 
                                        className='px-1' 
                                        alt = ''
                                        src = {checkSeveral} />
                                </div>
                            </div>
                        </div>
                    </div>
                { 
                props.loading ? <div className = "text-info col-12 col-lg-10" >Loading...</div> :
                    <div className='col-12 col-lg-10 px-4 d-flex flex-row flex-wrap results-wrapper'
                        onScroll = {handleScroll}>
                        {
                            props.data && props.data.length ? 
                            props.data.map(result => (
                                <div key = {result.id}
                                    className = {videoList.includes(result.id) ? 
                                        "col-6 col-md-4 col-lg-3 px-1 py-2 d-flex align-items-start bg-secondary border border-primary":
                                        "col-6 col-md-4 col-lg-3 px-1 py-2 d-flex align-items-start"}
                                    >
                                    <ResultComponent
                                        checkActive = {checkActive}
                                        result = {result}
                                        setVideo = {props.setVideo}
                                        setVideoList = {setVideoList}
                                        videoList = {videoList}
                                        setSelectedVideo = {setSelectedVideo}
                                    />
                                </div>
                                
                            )) : ""
                        }
                    </div>
                }
                </div>  
                <Modal 
                    trigger = {addActive}
                    setTrigger = {setAddActive}
                    top = {top}
                    right = {right}
                    content = {
                        <AddVideoToPlaylistContent
                            setIsActive = {setAddActive}
                            video_list = {videoList}
                            index = {2}
                        /> 
                    }
                />  
            </div>
        </>
    );
}

export default Results;