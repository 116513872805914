import React from 'react'
import Slide from "./Slide";
import GenreSlide from './GenreSlide';
import SidePlaylistComponent from '../videoPlayler/SidePlaylistComponent';
import SlideWithVideo from '../landing/SlideWithVideo';

export default function Slides(props) {
    const sWRightSpaceClass = props.displayCount === 3 ? 'slide-wrapper-for-four' : 'slide-wrapper-for-six'

    return (
        <>
            { props.tvData && props.tvData.length ?
                props.tvData.map(
                    (data, i) =>
                        <div 
                            onClick={() => props.slideClickHandler(data)}
                            className={'position-relative slide-wrapper ' + sWRightSpaceClass}
                            role='button'
                            key={i}
                            style={{
                                width: `${props.slideWrapperWidth}vw`,
                                height: '100%'
                            }}
                        >
                            {
                                props.type === 3? 
                                <GenreSlide
                                    item = {data}
                                    slideClickHandler = {props.slideClickHandler}

                                /> : 
                                props.type === 4 ?
                                <SidePlaylistComponent
                                    video = {data}
                                    slideClickHandler = {props.slideClickHandler}
                                /> :
                                props.type === 5 ? 
                                <SlideWithVideo
                                    item = {data}
                                    slideClickHandler = {props.slideClickHandler}
                                /> 
                                :
                                <Slide
                                    type = {props.type}
                                    tvData = {data}
                                    slideClickHandler = {props.slideClickHandler}
                                    width =  {"100%"}
                                />
                            }

                        </div>
                )
                : ''
            }
        </>
    )
}
