import React, {useEffect, useState} from 'react';
import AnalyzeHeader from './AnalyzeHeader';
import AnalyzeBody from './AnalyzeBody';
import { useDispatch, useSelector } from 'react-redux';
import { getAnalyzeInfo } from '../../actions/AnalyzeActions'

const AnalyzeComponent = ({currentTime, video, analyzeRef}) => {

    let time = 0

    const dispatch = useDispatch()

    const [activeData, setActiveData] = useState({})
    const [resultLink, setResultLink] = useState("")
    const [activeHeader, setActiveHeader] = useState(0)
    const [analyzeGenre, setAnalyzeGenre] = useState()
    const [analyzeMood, setAnalyzeMood] = useState()

    const { watermark_info, footprint_info } = useSelector(state=>state.analyze_info)

 
    useEffect(()=>{
        if(video && currentTime!==0){
            if (video.duration-30<currentTime){
                time = Math.floor((video.duration-30)/5)*5
            } else {
                time = Math.floor(currentTime/5)*5
            }
            dispatch(getAnalyzeInfo(video.id, currentTime, time))
        }
    },[currentTime])

    useEffect(()=>{
        setAnalyzeGenre()
        setAnalyzeMood()
        if (watermark_info && watermark_info.wm_track){
            setActiveData(watermark_info.wm_track); 
            setResultLink(watermark_info.result_link)
            if(watermark_info.wm_track.genre_title){
                setAnalyzeGenre(watermark_info.wm_track.genre_title)
            }
            setActiveHeader(1)
        } else if (footprint_info && footprint_info.fp_track){
            setActiveData(footprint_info.fp_track); 
            setResultLink(footprint_info.result_link)
            if(footprint_info.fp_track.fp && footprint_info.fp_track.fp.mood) {
                setAnalyzeMood(footprint_info.fp_track.fp.mood)
            }
            setActiveHeader(2)
        }

    },[watermark_info, footprint_info])

    useEffect(()=>{
        setActiveData()
    },[video])

    return (
        <>
            {
                activeData &&
                <div className = "container-fluid py-5 bg-bluedark d-flex justify-content-center" ref={analyzeRef}>
                    <div className='col-12 col-lg-11 bg-primary rounded px-5 py-5'>
                            <>
                                <AnalyzeHeader
                                    setActiveData = {setActiveData}
                                    setResultLink = {setResultLink}
                                    activeHeader={activeHeader}
                                    setActiveHeader={setActiveHeader}
                                    setAnalyzeGenre = {setAnalyzeGenre}
                                    setAnalyzeMood = {setAnalyzeMood}
                                />
                                <AnalyzeBody
                                    activeData = {activeData}
                                    resultLink = {resultLink}
                                    analyzeGenre = {analyzeGenre}
                                    analyzeMood = {analyzeMood}
                                />
                            </>
                    </div>
                </div>
            }
        </>
    );
}

export default AnalyzeComponent;