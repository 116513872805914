import React, { useEffect, useState} from 'react';
import { getPlaylistGroups, getGroupPlaylists} from '../../actions/PlaylistActions';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Slider from '../slides/Slider';

const Groups = (props) => {

    const dispatch = useDispatch()
    
    const [activeGroup, setActiveGroup] = useState({id:4, title:"Lounges"})

    const navigate = useNavigate()

    useEffect(()=>{
        dispatch(getPlaylistGroups())
    },[dispatch])

    const {playlistGroups} = useSelector(state=>state.playlistGroups)

    const {groupPlaylists} = useSelector(state => state.groupPlaylists)

    useEffect(()=>{

        dispatch(getGroupPlaylists(activeGroup.id))

    },[activeGroup])


const slideClickHandler = (item) => {
    localStorage.setItem('playlist_id', item.id)
    localStorage.setItem('video_id', item.video_id)
    localStorage.setItem('group_id', activeGroup.id)
    if(props.user) {
        navigate(encodeURI(`/home?g=${activeGroup.id}&p=${item.id}&v=${item.video_id}`))
    } else {
        navigate('/login')
    }
}

    return (
        <div className = 'pb-5'>
            <div className = 'w-100 my-3 d-flex flex-row pt-5 align-items-center justify-content-center flex-wrap'
                style={{gap:"0.5rem"}}>
                {
                    playlistGroups ?
                    playlistGroups.map(group =>
                        <div key = {group.id}
                            className = {group.id === activeGroup.id ? 
                                'd-flex align-items-center p-3 my-0 my-lg-1 bg-info text-primary rounded groups-components' : 
                                'd-flex align-items-center p-3 my-0 my-lg-1 bg-primary rounded text-info groups-components'}
                                role = "button"
                            onClick={()=>{
                                setActiveGroup(group); 
                                localStorage.setItem('group_id', group.id)}}>
                            {group.title}
                        </div>
                    ) : ''
                }
            </div>
            <div className="col-12">
            <div className='position-relative'>
                <Slider
                    num = {5}
                    type = {1}
                    showTitle = {false}
                    tvData={groupPlaylists}
                    slideClickHandler={slideClickHandler}
                />
            </div>
        </div>
        </div>
    );
};

export default Groups;