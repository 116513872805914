import React from 'react';
import { useSelector } from 'react-redux';

const AnalyzeHeader = (props) => {

    const { watermark_info, footprint_info, end_watermark, end_footprint} = useSelector(state=>state.analyze_info)

    return (
            <div className = "w-100 px-0 px-lg-3 d-flex pb-3 flex-column flex-md-row flex-lg-row justify-content-between align-items-center">
                <div className = "col-12 col-lg-3 col-md-6 text-center text-lg-start text-info fs-4 mb-2 mb-lg-0">      
                    ANALYSIS RESULTS
                </div>
                <div className = "col-12 col-lg-3 col-md-6 d-flex flex-wrap text-center justify-content-center justify-content-lg-start"
                    style={{gap:"0.8rem"}}
                >
                    { end_watermark && watermark_info.wm_track ?
                        <div role="button" className = { 
                                props.activeHeader === 1 ? 
                                    "bg-info text-white px-3 py-2 border border-info rounded-pill" :
                                    "text-info px-3 py-2 border border-info rounded-pill"
                                }
                            style={{width:"8.5rem"}}
                            onClick={()=>{
                                props.setActiveData(watermark_info.wm_track); 
                                props.setResultLink(watermark_info.result_link);
                                props.setActiveHeader(1)
                                if(watermark_info.wm_track.genre_title){
                                    props.setAnalyzeGenre(watermark_info.wm_track.genre_title)
                                }
                            }}
                        >WATERMARK</div> : ""
                    }
                    {
                        end_footprint && footprint_info.fp_track ?
                        <div role="button" className = { props.activeHeader === 2 ? 
                            "bg-info text-white px-3 py-2 border border-info rounded-pill" :
                                    "text-info px-3 py-2 border border-info rounded-pill"
                                }
                            style={{width:"8.5rem"}}
                        onClick={()=>{
                            props.setActiveData(footprint_info.fp_track); 
                            props.setResultLink(footprint_info.result_link);
                            if(footprint_info.fp_track && footprint_info.fp_track.genre_title){
                                props.setAnalyzeGenre(footprint_info.fp_track.genre_title)
                            }
                            if(footprint_info.fp_track && footprint_info.fp_track.fp && footprint_info.fp_track.fp.mood){
                                props.setAnalyzeMood(footprint_info.fp_track.fp.mood)
                            }
                            props.setActiveHeader(2)
                        }}>FOOTPRINT</div> : ""
                    }
                </div>
            </div>
    );
}

export default AnalyzeHeader;