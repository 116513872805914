import React, { useEffect } from 'react';
import Export from '../icons/Export.svg'
import deleteIcon from '../icons/delete.svg'
import OpenPlaylist from '../icons/OpenPlaylist.svg'
import ClosePlaylist from '../icons/ClosePlaylist.svg'
import ViewAll from '../icons/ViewAll.svg'
import { download_playlist } from '../../actions/PlaylistActions'
import { deletePlaylist } from '../../actions/PlaylistActions'
import { useDispatch, useSelector } from 'react-redux'
import replay from '../icons/rePlay.svg'
import { useNavigate } from 'react-router-dom'

const PlayerHeader = (props) => {


    const navigate = useNavigate()
    const {user} = useSelector(state=>state.user)
    const dispatch = useDispatch()
    const { playlistVideos, playlist_user_id, active_pl_id, active_pl_title } = useSelector(state => state.userPlaylists)
    
    const handleDelete = () => {
        if(window.confirm('Delete Playlist?')){
            dispatch(deletePlaylist(active_pl_id))
        }
    }

    const autoPlayHandler = () => {
        props.setVideo(playlistVideos[0])
        props.setSearchParams({ ...Object.fromEntries([...props.searchParams]), v:playlistVideos[0].id})
        localStorage.setItem('video_id', playlistVideos[0].id)
    }

    const viewAllHandler = () => {
        if(user ){
            if (user.id === playlist_user_id) {
                navigate(encodeURI(`/myPlaylist/${active_pl_id}/${active_pl_title}`))
            } else {
                navigate(encodeURI(`/playlist/${active_pl_id}/${active_pl_title}`))
            }
        } else {
            navigate('/login')
        }
      }

    return (

        <div className ={!props.playing || props.menuVisible ? 'col-12 py-3 d-flex flex-column flex-md-row px-1 justify-content-end visible' : 'col-12 py-3 d-flex flex-column flex-md-row px-1 justify-content-end hidden'} 
        >
            {
                active_pl_title && active_pl_title.length ? 
                <div className='col-12 col-md-9 col-lg-9 ps-3'>
                    <div className = "w-100 text-white fw-bold fs-7 overflow-hidden" 
                        title={active_pl_title} 
                        style = {{whiteSpace:"nowrap"}}>{active_pl_title.toUpperCase()}
                    </div> 
                </div>:""
            }

            <div className='col-12 col-md-4 ps-md-0 col-lg-3 d-flex justify-content-between align-items-center'
                onClick={(e)=>e.stopPropagation()}
            >
                <div className = "">
                        {
                            playlistVideos && playlistVideos.length &&
                            <div className = "text-white fs-3 ps-5"   
                                style={{fontWeight:"700"}}
                                >{`${playlistVideos.length} Videos`}
                            </div>
                        }
                </div>
                <div className='d-flex pe-3' style={{gap:"1.5rem"}}>
                    {
                      playlistVideos && playlistVideos.length && 
                      <>
                        <img src = {Export}  
                            role = "button"
                            alt = ''
                            style={{width:"1rem"}}
                            onClick={()=>dispatch(download_playlist(active_pl_id, active_pl_title))}/>
                        <img 
                            src ={replay}
                            alt = ''
                            onClick={()=>autoPlayHandler()}
                            role='button'
                            style={{width:"1rem"}} />
                        <img 
                            src ={ViewAll}
                            alt = ''
                            onClick={()=>viewAllHandler()}
                            role='button'
                            style={{width:"1rem"}} />
                        { user.id === playlist_user_id ?
                            <img src = {deleteIcon} 
                                role = "button"
                                alt = ''
                                style={{width:"1rem"}}s
                                    onClick={()=>handleDelete()} 
                                /> : ""
                        }
                        {
                            props.sliderVisible ? 
                            <img 
                                src ={ClosePlaylist}
                                alt = ''
                                onClick={(e)=>{e.stopPropagation(); props.setSliderVisible(false)}}
                                role='button'
                                style={{width:"1rem"}} /> :
                            <img 
                                src ={OpenPlaylist}
                                alt = ''
                                onClick={(e)=>{e.stopPropagation(); props.setSliderVisible(true)}}
                                role='button'
                                style={{width:"1rem"}} />
                        }
                        </>
                    }
                </div>
            </div>
        </div>
    );
}

export default PlayerHeader;
