import React, {useState} from 'react'
import Filter from './Filter'
import cuViewTV from '../icons/CuViewTV.svg'
import { useNavigate, useLocation } from 'react-router-dom'
import SearchContent from './SearchContent'
import filterIcon from '../icons/filterIcon.svg'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../actions/AuthActions';
import { Tooltip } from 'react-tooltip'
import search from '../icons/search.svg'


function Header(props) {

  const [filterActive, setFilterActive] = useState(false)
  const [profileActive, setProfileActive] = useState(false)
  const [searchActive, setSearchActive] = useState(false)
  const [searchVisible, setSearchVisible] = useState(false)
  const [searchValue, setSearchValue] = useState("")
  const { user } = useSelector(state=>state.user)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const location = useLocation();

  const logoutHandler = () =>{
    dispatch(logout())
    navigate('/')
  }

  const searchHandler = () => {
    if (searchVisible === false){
        setSearchVisible(true); 
        // setSearchActive(true)
    } else if (searchValue.length){
        navigate(`/search_results/${searchValue}/`)
        setSearchActive(false)
    } else {
        setSearchVisible(false)
        setSearchActive(false)
    }
  }

  const goHomeClickHandler = () => {
    let video_id = localStorage.getItem('video_id') ?  localStorage.getItem('video_id') : 403
    let playlist_id = localStorage.getItem('playlist_id') ?  localStorage.getItem('playlist_id') : 54
    let group_id = localStorage.getItem('group_id') ?  localStorage.getItem('group_id') : 4
    navigate(encodeURI(`/home?g=${group_id}&p=${playlist_id}&v=${video_id}`))
  }


  const scrollHandler = () => {
    if (location.pathname === '/'){
        props.newPlaylistsRef.current && props.newPlaylistsRef.current.scrollIntoView({ behavior: "smooth" })
    } else if(location.pathname === '/home') {
        props.myPlaylistsRef.current && props.myPlaylistsRef.current.scrollIntoView({ behavior: "smooth" })
    }
  }

  return (
    <div className='w-100' style={{position:'sticky', zIndex:1000, top:0}}>
        <div className="w-100 bg-primary d-flex justify-content-center" >
            <div className = "col-12 col-lg-11 py-2 mb-3 mb-lg-0 py-lg-3 d-flex flex-column flex-lg-row flex-md-column flex-sm-column justify-content-between align-items-center">
                <div className = "col-6 py-2 py-lg-1 col-lg-6 col-md-4 d-flex justify-content-center flex-column flex-md-row">
                    <img role="button"  
                        className='col-12 col-lg-5'
                        src={cuViewTV}  alt="CuviewTV TV logo" 
                        style={{height:"2.5rem"}}
                        onClick={()=>navigate('/')}/>
                    <div className='col-10 col-lg-6 ps-0 ps-lg-3 d-flex align-items-center'>
                        <div 
                            className='text-info px-2 fs-8' 
                            role='button'
                            onClick={()=>navigate('/')}
                            >NEWS</div>
                        <div 
                            className='text-info px-2 fs-8' 
                            onClick={()=>goHomeClickHandler()}
                            role='button'>CONTENT</div>
                            {
                                location.pathname === '/' || location.pathname === '/home' ?
                                <div 
                                className='text-info px-2 fs-8' 
                                onClick={()=>scrollHandler()}
                                role='button'>PLAYLIST</div> : ''
                            }

                    </div>
                </div>
                <div className = 'col-6 d-flex flex-column-reverse flex-md-row align-items-center'>
                    <div className="col-12 col-lg-8 d-flex align-items-center justify-content-end"
                    >
                        {
                            searchVisible && 
                            <SearchContent
                                searchActive = {searchActive}
                                setSearchActive = {setSearchActive}
                                setFilterActive = {setFilterActive}
                                searchValue = {searchValue}
                                setSearchValue ={setSearchValue}
                            />
                        }
                    </div>
                    <div className = 'col-6 col-lg-4 d-flex justify-content-center position-relative'>
                    <div className='col-11 d-flex p-1 justify-content-around justify-content-md-start' style={{gap:"20px"}}>
                        <div className='col-2 p-1 d-flex justify-content-end ' >
                            <img src={search} alt="search icon" role= "button" className='p-1' 
                                onClick={()=>searchHandler()} 
                                />
                        </div>
                        <div className='col-2 h-100 p-2 d-flex justify-content-center' data-tooltip-id="my-tooltip" data-tooltip-content="For More Search Options Click Here!">
                        <img src = {filterIcon} 
                            className = ""
                            role='button'
                            onClick={()=>setFilterActive(!filterActive)}
                            alt = ""/>  
                            {
                            !filterActive && !searchActive ?
                            <>
                                <Tooltip id = 'new-tooltip' className='bg-info' style={{zIndex:'1000'}}/>
                                <Tooltip id="my-tooltip" className='bg-info' style={{zIndex:'1000'}}/>
                            </> : ""
                            }
                        </div>
                        {
                            user && user.first_name && user.last_name ? 
                            <div className='col-2 d-flex flex-column flex-md-row h-100 justify-content-center justify-content-md-start align-items-center'
                                onClick={()=>setProfileActive(!profileActive)} >
                                    <div className=' border border-2 p-2 border-info rounded-circle d-lex justify-content-center align-items-center'>
                                        <div  
                                            alt="person icon" 
                                            role='button'
                                            className = "fw-bold text-info text-center overflow-hidden text-nowrap" 
                                            >{user.first_name[0].toUpperCase() + user.last_name[0].toUpperCase()}</div>
                                        </div>
                                    </div> : 
                                <div className = 'text-info text-underline fs-2 fw-bold d-flex align-items-center text-decoration-underline'
                                    role = 'button'
                                    onClick={()=>{navigate('/login')}}
                                >Login</div>
                            }
                        </div>
                    {
                        profileActive && 
                        <div 
                            className = "col-8 px-1 px-lg-2 py-1 mt-5 ms-0 ms-lg-5 bg-white position-absolute text-center rounded" 
                            style={{zIndex:"999"}}
                            onMouseLeave = {()=> setProfileActive(false)}>
                            <div className = "px-3 py-2 w-100 rounded color-secondary profile-menu-object" 
                            onClick = { logoutHandler } >Logout
                            </div>
                            {/* <div className = "px-1 fs-5 fs-md-6 py-2 w-100 text-nowrap rounded color-secondary profile-menu-object" 
                                onClick={()=>{ props.myPlaylistsRef.current && props.myPlaylistsRef.current.scrollIntoView({ behavior: "smooth" })}}
                            >My Playlists
                            </div> */}
                            {
                            user && user.is_staff ?
                            <div className = "px-1 fs-5 fs-md-6 py-2 w-100 text-nowrap rounded color-secondary profile-menu-object" 
                                onClick={()=>navigate(`/main`)}
                                >Admin Page
                            </div> : ""
                            }
                        </div>
                    }
                    </div>
                </div>
            </div>
        </div>
        {
            filterActive &&
            <Filter
             />
        }

    </div>
  );
}

export default Header;