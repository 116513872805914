import React, { useEffect } from 'react';
import Slider from '../slides/Slider';
import {useNavigate} from 'react-router-dom'
import { getConcertsAndPerformances} from '../../actions/PlaylistActions'
import { useDispatch, useSelector } from 'react-redux'
import ArrowRight from '../icons/Arrow_right.svg'


function Concerts(props) {


    const dispatch = useDispatch()

    const navigate = useNavigate()

    const playlistList = useSelector(state => state.concertsAndPerformances)

    const { error, concertsAndPerformances} = playlistList

    useEffect(()=>{

        dispatch(getConcertsAndPerformances())

    },[dispatch])


      useEffect(()=>{
        props.playlists && props.playlists.length && props.slideRef?.slickGoTo(0)
    },[props.playlists])



      const viewAllHandler = () => {
        if(props.user){
          navigate(encodeURI(`/group/5/Concerts and Performances`))
        } else {
          navigate('/login')
        }
      }


    return (
        <div className="col-12" style = {{marginTop:"2.5vw"}}>
            <div className="col-12 ps-5 d-flex align-items-center pb-2" style = {{ fontSize:"1.25rem", color:'white'}}>
                <span className="text-white fw-bold fs-7 ps-4 pe-3">New Concerts</span>
                <img src = {ArrowRight} onClick={()=>viewAllHandler()} alt = '' style={{cursor:"pointer"}}/>
            </div>

                {
                    concertsAndPerformances && concertsAndPerformances && concertsAndPerformances.length ?
                    <Slider
                        num = {5}
                        type = {1}
                        showTitle = {false}
                        tvData={concertsAndPerformances}
                        slideClickHandler={props.slideClickHandler}
                    /> : ""
                }   
        </div>
    );
}

export default Concerts;