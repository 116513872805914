import React from 'react';

const GenreSlide = (props) => {


    return (
        <div className = 'rounded' role = 'button'
             onClick={()=>props.slideClickHandler(props.item)}
            >   
            <div className='w-100' style={{display:'contents'}}> 
                <img 
                    className='w-100 rounded-top'
                    src = {`https://img.cugate.com/?o=CUTV_GENRE&i=${props.item.id}&s=300`} 
                    alt = '' 
                    />
                <div className = 'w-100' >
                    <div className='w-100 bg-secondary text-center py-2 text-info rounded-bottom genre_titles'>
                        {props.item.title}
                    </div>
                </div> 
            </div> 
        </div>
    );
};

export default GenreSlide;