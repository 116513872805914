import React, { useState, useRef, useEffect } from 'react'
import plusVideo from '../icons/plusVideo.svg'
import deleteVideo from '../icons/deleteVideo.svg'
import shareVideo from '../icons/shareVideo.svg'
import default_cover from '../images/default_cover.jpg'
import { deleteVideoFromPlaylist } from '../../actions/PlaylistActions'
import { useSelector, useDispatch } from 'react-redux'
import ShareContent from './ShareContent'
import AddVideoToPlaylistContent from '../playlistAdds/AddVideoToPlaylistContent'
import Modal from '../Modal'
import useWindowDimentions from '../useWindowDimentions'

const SidePlaylistComponent = (props) => {

    const [menuVisible, setMenuVisible] = useState(false)

    const [sideShareActive, setSideShareActive] = useState(false)

    const [addsActive, setAddsActive] = useState(false)

    const [right, setRight] = useState(0)

    const [top, setTop] = useState(0)

    const {width, height} = useWindowDimentions()

    const {user} = useSelector(state=>state.user)

    const dispatch = useDispatch()

    const get_members = (data) =>{

        let members = '' 
        
        if (data.artists && data.artists.length){
            members = data.artists
        }

        if (data.composers && data.composers.length){
            let c = data.composers.split(',')
            for (let i in c){
                if (!members.includes(c[i])){
                    members += ', '+ c[i]
                }
            }    
        }
        return members
    }

    function toTimeString(seconds) {
        let inTime = (new Date(seconds * 1000)).toUTCString().match(/(\d\d:\d\d:\d\d)/)[0];
        if(seconds < 3600){
            inTime = inTime.slice(3, 8)
        }
        return (inTime)
      }


    const addClickHandler = (e) => {
        console.log(e.clientX)
        console.log(width)
        if (e.clientX < 450){
            setRight(width-450)
        } else {
            setRight(width-e.clientX+5)
        }
        setTop(e.clientY)
        setAddsActive(true)
    }

    const shareClickHandler = (e) => {
        setRight(width-e.clientX+5)
        setTop(e.clientY-20)
        setSideShareActive(true)
    }

    return (
        <div
            className = 'w-100 d-flex position-relative flex-row px-2'
            id={props.video.position}
            
            >
            <div className = 'position-relative'
                // role = "button"
                style={
                    menuVisible ? {
                        border:'solid', 
                        borderColor:'white', 
                        borderRadius:'5px', 
                        borderWidth:'3px',
                        boxSizing: 'border-box'
                    }:{
                        border:'solid', 
                        borderColor:'transparent', 
                        borderRadius:'5px', 
                        borderWidth:'3px',
                        boxSizing: 'border-box'
                    }}
                onClick={()=>{props.slideClickHandler(props.video)}}
                onMouseEnter={()=>setMenuVisible(true)}
                onMouseLeave={()=>setMenuVisible(false)}
            >
                <img src = {`https://img.cugate.com/?o=CUTV_VIDEO&i=${props.video.id}&s=300`}
                    className = "w-100 rounded object-fit-cover"
                    role = "button"
                    alt = ''
                    style={{minHeight:"100%", aspectRatio:"16/9"}}
                    onError = {({ currentTarget }) => {
                        currentTarget.onerror = null // prevents looping
                        currentTarget.src=default_cover
                        }}
                />
                <div className = "position-absolute text-white px-1 rounded bg-dark"
                    style={{zIndex:"1", top:"0.5rem", right:"0.2rem"}}>
                        {toTimeString(props.video.duration)}
                </div>
                {
                    menuVisible && 
                    <div className="w-100 position-absolute d-flex justify-content-between"
                    style={{zIndex:'1', bottom:0, backgroundColor:'#07294CB2', height:'50%'}}
                >
                    <div>
                        <div className = 'text-white fw-bold overflow-hidden pt-1 ps-2' 
                            title={props.video.title}
                            style={{textOverflow:"ellipsis"}}>{props.video.title}
                            <span className='text-white fw-light'> | </span>
                            <span className = 'text-white fst-italic fw-light fs-5 overflow-hidden' 
                                title={get_members(props.video)}
                                style={{maxHeight:"2.5rem"}}> {get_members(props.video)}</span>
                            </div>
                        </div>
                    <div>
                        <div className='px-2 py-2 d-flex h-100 flex-column rounded justify-content-between' 
                            style={{ zIndex:1, bottom:0}}>
                            <img 
                                src = {plusVideo}
                                onClick={(e)=>{e.stopPropagation(); addClickHandler(e)}}
                                alt = ""
                                role='button'/>
                            {
                                props.playlist_user_id === user.id ? 
                                <img 
                                    src = {deleteVideo}
                                    alt = ""
                                    onClick={(e)=> {e.stopPropagation(); dispatch(deleteVideoFromPlaylist(props.video.id, props.playlist_id, props.video.duration))}}
                                    role='button'/> : ""
                                }
                            <img 
                                src = {shareVideo}
                                alt = ""
                                onClick={(e)=>{e.stopPropagation(); shareClickHandler(e)}}
                                role='button'/>
                        </div>
                    </div>

                </div>
                }
            </div>
                <Modal 
                    trigger = {sideShareActive}
                    setTrigger = {setSideShareActive}
                    top = {top}
                    right = {right}
                    content = {
                        <ShareContent
                            setShareActive = {setSideShareActive}
                            video = {props.video}
                        /> 
                    }
                />        
                <Modal 
                    trigger = {addsActive}
                    setTrigger = {setAddsActive}
                    top = {top}
                    right = {right}
                    content = {
                        <AddVideoToPlaylistContent
                            setIsActive = {setAddsActive}
                            video_list = {[props.video.id]}
                            index = {1}
                            video = {props.video}
                        /> 
                    }
                />             
        </div>
    );
}

export default SidePlaylistComponent;