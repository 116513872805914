import React from 'react';
import default_cover from '../images/default_cover.jpg'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { useNavigate } from 'react-router-dom';

const UserPlaylistComponent = (props) => {

    const navigate = useNavigate()


    const { attributes, listeners, setNodeRef, transform, transition  } = useSortable(
        {id: props.video.position})

    const style = {
        transition,
        transform: CSS.Transform.toString(transform),
        gap:"10px"
    }

    

    const get_members = (data) =>{
        let members = ''
    
        if (data.artists && data.artists.length){
          members = data.artists
      }
    
      if (data.composers && data.composers.length){
          let c = data.composers.split(',')
          for (let i in c){
              if (!members.includes(c[i])){
                  members += ', '+ c[i]
              }
          }    
      }
        return members
    }


    function toTimeString(seconds) {
        let inTime = (new Date(seconds * 1000)).toUTCString().match(/(\d\d:\d\d:\d\d)/)[0];
        if(seconds < 3600){
            inTime = inTime.slice(3, 8)
        }
        return (inTime)
      }


      const clickHandler = (video) => {
        let group_id = localStorage.getItem('group_id')
        localStorage.setItem('video_id', video.id)
        localStorage.setItem('playlist_id', props.playlist_id)
        navigate(encodeURI(`/home?g=${group_id}&p=${props.playlist_id}&v=${video.id}`))
    }


    return (
        <div 
            key = {props.video.id}
            id={props.video.position}
            ref = {setNodeRef}
            {...attributes}
            {...listeners}
            style = {style}
            className = "col-6 col-md-4 col-lg-3 px-1 py-2 d-flex align-items-start"
            >
            <div className='w-100' onClick={()=>clickHandler(props.video)}>
                <div className='position-relative'
                >
                    <img src={ `https://img.cugate.com/?o=CUTV_VIDEO&i=${props.video.id}&s=300`} alt = ""
                        onError = {({ currentTarget }) => {
                            currentTarget.onerror = null // prevents looping
                            currentTarget.src=default_cover
                        }}
                        role='button'
                        className='w-100 px-1'
                        style={
                            {
                                objectFit: 'cover',
                                borderRadius:'0.625vw',
                                aspectRatio: "16/9"
                            }
                        }>
                        </img>
                        <div className='position-absolute text-white rounded px-1 bg-dark'
                            style={{zIndex:"2", bottom:"0.05rem", right:"0.2rem"}}>
                            {toTimeString(props.video.duration)}
                        </div>
                </div>
                <div className = "w-100 d-flex flex-row align-items-start pt-1 pt-lg-3">
                    <div
                        className='ms-0 w-100 ms-lg-3 text-wrap px-1 d-flex flex-column'
                        >
                        <h6
                            className='text-white fw-bold overflow-hidden m-0'
                            // style={{height:"auto"}}
                            title={props.video.title}>{props.video.title}</h6>
                            {
                                get_members(props.video).length ? 
                                <h6
                                    className='text-info overflow-hidden'
                                    style={{maxHeight:'40px'}}
                                    title={get_members(props.video)}>{get_members(props.video)}</h6> : ""
                            }
                    </div>
                </div>
            </div> 
        </div>
    );
};

export default UserPlaylistComponent;