import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { listPlaylistVideos } from '../../actions/PlaylistActions'
import Slider from '../slides/Slider'
import ArrowRight from '../icons/Arrow_right.svg'
import { useNavigate } from 'react-router-dom'

const CurrentPlaylist = (props) => {

    const playlistVideosList = useSelector(state => state.userPlaylists)
    const {user} = useSelector(state=>state.user)

    const dispatch = useDispatch()

    const navigate = useNavigate()

    // const [sliderVisible, setSliderVisible] = useState(true)

    let playlist_id = parseInt(props.searchParams.get('p')) ? parseInt(props.searchParams.get('p')) : localStorage.getItem('playlist_id') ? localStorage.getItem('playlist_id') : 54

    const { playlistVideos, active_pl_title, active_pl_id, playlist_user_id } = playlistVideosList
    
    useEffect(()=>{
        dispatch(listPlaylistVideos(playlist_id))
    },[dispatch])



      const videoClickHandler = (v) => {
        props.setVideo(v)
        localStorage.setItem('video_id', v.id)
        props.setSearchParams({ ...Object.fromEntries([...props.searchParams]), v:v.id})
    }


    const viewAllHandler = () => {
        if(user ){
            if (user.id === playlist_user_id) {
                navigate(encodeURI(`/myPlaylist/${active_pl_id}/${active_pl_title}`))
            } else {
                navigate(encodeURI(`/playlist/${active_pl_id}/${active_pl_title}`))
            }
        } else {
            navigate('/login')
        }
      }

    return (
        <>

                <div className="col-12" style = {{ marginBottom:"2.5vw"}}>
                    <div className="col-12 ps-5 d-flex align-items-center pb-2" style = {{ fontSize:"1.25rem", color:'white'}}>
                        <span className="text-white fw-bold fs-7 ps-4 pe-3">{active_pl_title}</span>
                        <img src = {ArrowRight} onClick={()=>viewAllHandler()} alt = '' style={{cursor:"pointer"}}/>
                    </div>
        
                    {
                            playlistVideos && playlistVideos.length ? 
                            <Slider
                            num = {6}
                            type = {4}
                            showTitle = {false}
                            tvData={playlistVideos}
                            slideClickHandler={videoClickHandler}
                        /> : ''
                        }  
                </div>
                
        </>
    );
};

export default CurrentPlaylist;